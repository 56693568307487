import {
  IoReorderFourSharp,
  IoReorderThreeSharp,
  IoReorderTwoSharp,
} from 'react-icons/io5';

import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
  AiOutlineNotification,
  AiOutlineIssuesClose,
  AiOutlineClockCircle,
  AiOutlinePushpin,
} from 'react-icons/ai';

interface IConstant {
  label: string;
  value: string | number;
  color?: string;
  icon?: React.ReactElement;
}

export const urgencyDegree: IConstant[] = [
  {
    label: 'BAIXO',
    value: 0,
    color: '#03a9f4',
    icon: <IoReorderTwoSharp size={11} className="me-1" />,
  },
  {
    label: 'ALTO',
    value: 1,
    color: '#ff9800',
    icon: <IoReorderThreeSharp size={11} className="me-1" />,
  },
  {
    label: 'MUITO ALTO',
    value: 2,
    color: '#e91e63',
    icon: <IoReorderFourSharp size={11} className="me-1" />,
  },
  {
    label: 'CRÍTICO',
    value: 3,
    color: '#f44336',
    icon: <IoReorderFourSharp size={11} className="me-1" />,
  },
  {
    label: 'NENHUM',
    value: 4,
    color: '#666',
    icon: undefined,
  },
];

export const urgencyDegreeColor: any = {
  0: '#03a9f4',
  1: '#ff9800',
  2: '#e91e63',
  3: '#f44336',
  4: '#666',
};

export const status: IConstant[] = [
  { label: 'APPROVED', value: 0, color: '#4caf50' },
  { label: 'REVIEWING', value: 1, color: '#3f51b5' },
  { label: 'REJECTED', value: 2, color: '#f44336' },
];

export const stage: IConstant[] = [
  { label: 'TODO', value: 0 },
  { label: 'DOING', value: 1 },
  { label: 'DONE', value: 2 },
  { label: 'ICEBODX', value: 3 },
];

export const stageTranslation: IConstant[] = [
  { label: 'PENDENTES', value: 0 },
  { label: 'EM PROGRESSO', value: 1 },
  { label: 'FINALIZADO', value: 2 },
  { label: 'EM PAUSA', value: 3 },
];

export interface IFilterTask {
  orderBy: 'id' | 'stage' | 'urgencyDegree' | 'status';
  orderDirection: 'ASC' | 'DESC';
}

export const orderBy: IConstant[] = [
  { label: 'ID', value: 'id' },
  { label: 'Estágio', value: 'stage' },
  { label: 'Grau de Urgência', value: 'urgencyDegree' },
  { label: 'Status', value: 'status' },
];

export const orderDirection: IConstant[] = [
  { label: 'Ascendente', value: 'ASC' },
  { label: 'Descendente', value: 'DESC' },
];

export const orderByTranslation: any = {
  id: (
    <div className="d-flex align-items-center">
      <AiOutlineClockCircle size={22} className="me-1" /> ID
    </div>
  ),
  stage: (
    <div className="d-flex align-items-center">
      <AiOutlinePushpin size={22} className="me-1" /> Estágio
    </div>
  ),
  urgencyDegree: (
    <div className="d-flex align-items-center">
      <AiOutlineNotification size={22} className="me-1" /> Grau de Urgência
    </div>
  ),
  status: (
    <div className="d-flex align-items-center">
      <AiOutlineIssuesClose size={22} className="me-1" /> Status
    </div>
  ),
};

export const orderDirectionTranslation: any = {
  ASC: (
    <div className="d-flex align-items-center">
      <AiOutlineSortAscending size={22} className="me-1" /> Ascendente
    </div>
  ),
  DESC: (
    <div className="d-flex align-items-center">
      <AiOutlineSortDescending size={22} className="me-1" /> Descendente
    </div>
  ),
};
