import styled from 'styled-components';

import { Avatar as AvatarAntd } from 'antd';

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  b {
    font-size: 20px;
  }
`;

export const Avatar = styled(AvatarAntd)<{ principal?: boolean }>`
  background-color: #4174b4;
  color: white;
  border-color: ${(props: any) => (props?.principal ? '#ffc107' : 'white')};
  border-width: 2px;
`;

export const Link = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
