import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Select,
  Radio,
  Input,
  Divider,
  DatePicker,
} from 'antd';
import type { RadioChangeEvent } from 'antd';

import { Card } from 'src/components';
import { urgencyDegree } from 'src/utils/constants/tasks';
import { IReduxState } from 'src/store';

import { IProps } from './form.type';
import { rules } from './form.utils';

const { TextArea } = Input;

const FormComponent: React.FC<IProps> = (props) => {
  const { operators, patrimonies } = props;

  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const user = useSelector((state: IReduxState) => state.auth?.user);
  const sectors = useSelector((state: IReduxState) => state.sectors.all);
  const cells = useSelector((state: IReduxState) => state.cells.all);

  const cellOptions = cells?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));

  const options = sectors?.map((item: any) => ({
    label: item.name || 'Não Informado',
    value: item.id,
  }));

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Card>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Setor:" name="sectorId">
            <Radio.Group
              options={options}
              onChange={onChange}
              value={value}
              buttonStyle="solid"
              optionType="button"
            />
          </Form.Item>
        </Col>
        <Col span={24} lg={14}>
          <Form.Item label="Células:" name="cellId">
            <Radio.Group
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                fontWeight: 'bold',
              }}
              options={cellOptions?.slice(0, 5)}
              onChange={onChange}
              value={value}
            />
          </Form.Item>
        </Col>
        <Col span={24} lg={10}>
          <Form.Item
            label="Motivo:"
            name="description"
            rules={rules.description}
          >
            <TextArea placeholder="Motivo" rows={4} />
          </Form.Item>

          <Form.Item
            label="Patrimônio (Máquina):"
            name="patrimonyId"
            rules={rules.patrimonyId}
          >
            <Select
              showSearch
              placeholder="Patrimônio (Máquina)"
              optionFilterProp="children"
              filterOption={filterOption}
              options={patrimonies}
            />
          </Form.Item>

          <Form.Item
            label="Prioridade (Bandeira):"
            name="urgencyDegree"
            rules={rules.urgencyDegree}
          >
            <Select
              onSelect={(e) => console.log(e)}
              options={urgencyDegree}
              placeholder="Selecione..."
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={24} lg={9}>
          <Form.Item label="Responsável:" name="userId">
            <Input
              placeholder="Responsável"
              readOnly
              defaultValue={user.name}
            />
          </Form.Item>
        </Col>

        <Col span={24} lg={9}>
          <Form.Item label="Operador:" name="operatorId">
            <Select
              showSearch
              placeholder="Operador"
              optionFilterProp="children"
              filterOption={filterOption}
              options={operators}
            />
          </Form.Item>
        </Col>

        <Col span={24} lg={6}>
          <Form.Item label="Data da ocorrência:" name="occurredAt">
            <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(undefined, {})(FormComponent);
