import React, { useState } from 'react';
import { Divider } from 'antd';
import { connect } from 'react-redux';
import { FcSupport } from 'react-icons/fc';
import { fetchTechnicians } from 'src/store/technicians/actions';

import { Card } from 'src/components';
import Group from './components/group/group.component';
import Notifications from './components/notifications/notifications.component';
import FormTechnicians from './components/form-technicians/form-technicians.component';

import { IProps } from './technicians.type';
import { Title, Link } from './technicians.style';
import useTechnicians from './technicians.use';

const TechniciansComponent: React.FC<IProps> = (props) => {
  const { task, cardId, handleTask } = props;
  const { handleTechnicians, technicians, loading } = useTechnicians(props);

  const [editing, setEditing] = useState(false);

  const onEditing = async (value?: boolean) => {
    setEditing(!!value);
    !!value && (await handleTechnicians());
  };

  return (
    <Card className="mb-3">
      <Title>
        <FcSupport size={20} className="me-2" />
        <h4 className="m-0">Técnicos</h4>
      </Title>
      <Divider className="mt-2 mb-3" />
      {editing ? (
        <FormTechnicians
          loading={loading}
          cardId={cardId}
          handleTask={handleTask}
          handleEditing={onEditing}
          technicians={technicians}
        />
      ) : !!task.technicianTasks.length ? (
        <>
          <Group task={task} />
          <Link onClick={() => onEditing(true)}>Alterar técnico(s)</Link>
        </>
      ) : (
        <Notifications onEditing={onEditing} />
      )}
    </Card>
  );
};

export default connect(undefined, { fetchTechnicians })(TechniciansComponent);
