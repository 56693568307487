import React from 'react';
import { Alert, Form, Input, Button } from 'antd';
import { connect } from 'react-redux';

import { IProps } from './advice.type';
import { Wrapper, Message, Link } from './advice.style';
import { fetchTask, updateTask } from 'src/store/tasks/actions';
import useTask from './advice.use';

const AdviceComponent: React.FC<IProps> = (props) => {
  const { listId } = props;
  const { editing, onEditing, sending, handleSubmit, task } = useTask(props);

  return (
    <Wrapper>
      {editing ? (
        <Alert
          className="mt-3 mb-2"
          description={
            <Form
              id="form-tech"
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{ technical_advice: task?.technical_advice }}
            >
              <Form.Item name="technical_advice">
                <Input.TextArea
                  rows={4}
                  placeholder="Informe o parecer técnico da ordem de serviço..."
                />
              </Form.Item>

              <Button
                size="small"
                type="default"
                htmlType="submit"
                loading={sending}
              >
                <small>SALVAR</small>
              </Button>
            </Form>
          }
          message={<b>Parecer técnico:</b>}
          type="info"
        />
      ) : (
        <Alert
          className="mt-3 mb-2"
          description={
            <>
              <Message>{task?.technical_advice || 'Não informado'}</Message>
              <Link className="mt-2" onClick={onEditing}>
                {listId === 1
                  ? task?.technical_advice
                    ? 'Editar parecer técnico'
                    : 'Informar parecer técnico'
                  : null}
              </Link>
            </>
          }
          message={<b>Parecer técnico:</b>}
          type="info"
        />
      )}
    </Wrapper>
  );
};

export default connect(undefined, { fetchTask, updateTask })(AdviceComponent);
