import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Notification } from 'src/utils';
import { IReduxState } from 'src/store';
import { ITask } from 'src/interfaces/task';

import { IProps } from './tasks.type';

const useTasks = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState<ITask[] | undefined>(undefined);
  const filter = useSelector((state: IReduxState) => state.tasks.filter);

  const handleTasks = async () => {
    setLoading(true);

    try {
      const response = await props.fetchTasks(filter);

      if (response) {
        setTasks(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a listagem de tarefas, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  return { handleTasks, loading, tasks };
};

export default useTasks;
