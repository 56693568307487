import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space, Typography } from 'antd';

import {
  orderByTranslation,
  orderDirectionTranslation,
  orderBy,
  orderDirection,
} from 'src/utils/constants/tasks';

import { IProps } from './filter.type';
import { Wrapper } from './filter.style';
import { IReduxState } from 'src/store';
import { connect, useDispatch } from 'react-redux';

const itemsOrderBy: MenuProps['items'] = orderBy.map((item) => ({
  key: item.value,
  label: item.label,
}));

const itemsOrderDirection: MenuProps['items'] = orderDirection.map((item) => ({
  key: item.value,
  label: item.label,
}));

const FilterComponent: React.FC<IProps> = (props) => {
  const { filter } = props;

  const dispatch = useDispatch();

  const handleFilter = (e: any, type: 'orderBy' | 'orderDirection') => {
    switch (type) {
      case 'orderBy':
        dispatch({
          type: 'UPDATE_TASKS_FILTER',
          payload: {
            ...filter,
            orderBy: e.key,
          },
        });

        return props.handleReload();
      case 'orderDirection':
        dispatch({
          type: 'UPDATE_TASKS_FILTER',
          payload: {
            ...filter,
            orderDirection: e.key,
          },
        });

        return props.handleReload();
      default:
        return props.handleReload();
    }
  };

  return (
    <Wrapper>
      <p>
        Mostrando as <b>ordens de serviço</b> por
      </p>
      <Dropdown
        className="mx-2"
        menu={{
          items: itemsOrderBy,
          selectable: true,
          onSelect: (e) => handleFilter(e, 'orderBy'),
        }}
      >
        <Typography.Link
          style={{
            backgroundColor: '#eee',
            borderRadius: 2,
            paddingTop: 6,
            paddingRight: 6,
            paddingLeft: 6,
          }}
        >
          <Space>
            {orderByTranslation[filter.orderBy]}
            <DownOutlined />
          </Space>
        </Typography.Link>
      </Dropdown>
      <p>
        em <b>ordem</b>
      </p>
      <Dropdown
        className="mx-2"
        menu={{
          items: itemsOrderDirection,
          selectable: true,
          onSelect: (e) => handleFilter(e, 'orderDirection'),
        }}
      >
        <Typography.Link
          style={{
            backgroundColor: '#eee',
            borderRadius: 2,
            paddingTop: 6,
            paddingRight: 6,
            paddingLeft: 6,
          }}
        >
          <Space>
            {orderDirectionTranslation[filter.orderDirection]}
            <DownOutlined />
          </Space>
        </Typography.Link>
      </Dropdown>
    </Wrapper>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  filter: state.tasks.filter,
});

export default connect(mapStateToProps, {})(FilterComponent);
