import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Tooltip } from 'antd';

import { IProps } from './group.type';

const GroupTechniciansComponent: React.FC<IProps> = (props) => {
  const { task } = props;

  return (
    <div>
      <Avatar.Group
        maxCount={2}
        size="small"
        maxStyle={{
          color: '#4174b4',
          backgroundColor: '#d6e5ec',
        }}
      >
        {task.technicianTasks.map((item: any, index: number) => {
          return (
            <Tooltip key={index} title={item.technician.name} placement="top">
              <Avatar size="large">{item.technician.name[0]}</Avatar>
            </Tooltip>
          );
        })}
      </Avatar.Group>
    </div>
  );
};

export default connect(undefined, {})(GroupTechniciansComponent);
