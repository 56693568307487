import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  IReducerTasksState,
  FETCH_TASK,
  FETCH_TASKS,
  UPDATE_TASKS_FILTER,
  TasksActionTypes,
} from './types';

const initialState: IReducerTasksState = {
  all: undefined,
  current: undefined,
  filter: {
    orderBy: 'urgencyDegree',
    orderDirection: 'DESC',
  },
};

const persistConfig = {
  storage,
  key: 'tasks',
  whitelist: ['all', 'filter'],
};

const reducers = (state = initialState, action: TasksActionTypes) => {
  switch (action.type) {
    case FETCH_TASKS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_TASK:
      return {
        ...state,
        current: action.payload,
      };
    case UPDATE_TASKS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
