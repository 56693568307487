import { ITask, IFilterTask } from 'src/interfaces/task';

export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_TASK = 'FETCH_TASK';
export const UPDATE_TASKS_FILTER = 'UPDATE_TASKS_FILTER';

export interface IReducerTasksState {
  all?: ITask[];
  current?: ITask;
  filter: IFilterTask;
}

export interface IUpdadeTasksFilter {
  type: typeof UPDATE_TASKS_FILTER;
  payload: IFilterTask;
}

export interface IFetchTasks {
  type: typeof FETCH_TASKS;
  payload: ITask[];
}

export interface IFetchTask {
  type: typeof FETCH_TASK;
  payload: ITask;
}

export type TasksActionTypes = IFetchTasks | IFetchTask | IUpdadeTasksFilter;
