import styled from 'styled-components';

import { Card as GCard } from 'src/components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  color: #666;
  margin-top: 10px;
  margin-bottom: 0;

  p {
    margin-bottom: 0;
  }
`;

export const Card = styled(GCard)`
  border-radius: 10px;

  .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
