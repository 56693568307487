import { Dispatch } from 'react';

import CellsService from 'src/services/cells.service';

import { ICell } from 'src/interfaces/cell';
import { request } from 'src/utils';

import { FETCH_CELLS, FETCH_CELL, CellsActionTypes } from './types';

export const fetchCells =
  () => async (dispatch: Dispatch<CellsActionTypes>) => {
    try {
      const response: any = await CellsService.all();

      dispatch({
        type: FETCH_CELLS,
        payload: response.data,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchCell =
  (id: string) => async (dispatch: Dispatch<CellsActionTypes>) => {
    try {
      const response: ICell = await CellsService.current(id);

      dispatch({
        type: FETCH_CELL,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };
